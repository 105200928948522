export enum PageNameConfig {
  LOGIN_PAGE = 'login',
  PWD_FORGOT = 'pwd_forgot',
  PWD_RESET = 'pwd_reset',
  PWD_CHANGE = 'pwd_change',
  WRITING_PAGE = 'writing',
}

export enum PageEvent {
  PAGE_SHOW = 'page_show',
  PAGE_HIDE = 'page_hide',
}

export enum LoginPageEvent {
  CLICK_LOGIN_BUTTON = 'click_login_button',
  CLICK_FORGOT_PASSWORD = 'click_forgot_password',
}

export enum ModuleName {
  LOGIN_FORM = 'login_form',
  PWD_FORGOT_FORM = 'pwd_forgot_form',
  PWD_RESET_FORM = 'pwd_reset_form',
  PWD_CHANGE_FORM = 'pwd_change_form',
  WRITING_SIDEBAR = 'writing_sidebar',
  WRITING_MENU_TOOLBAR = 'menu_toolbar',
  GENERATE_MENU_TOOLBAR = 'generate_menu_toolbar',
  AI_BOT = 'ai_bot',
  AI_PROOFREADING = 'ai_proofreading',
  AI_WRITING_INPUT = 'ai_writing_input',
}

export enum LoginType {
  // 账号密码登录
  ACCOUNT_PASSWORD = 'account_password',
  // 邮箱加验证码登录
  EMAIL_CODE = 'email_code',
  // 手机加验证码登录
  MOBILE_CODE = 'mobile_code',
}

export enum ForgotPasswordEvent {
  SEND_RESET_PASSWORD_LINK = 'send_reset_password_link',
  RESET_PASSWORD_RO_BACK = 'rollback',
}

export enum ResetPasswordEvent {
  CAPTCHA_SEND = 'captcha_send',
  CLICK_RESET_PASSWORD_BUTTON = 'click_reset_password_button',
  CLICK_CONFIRM_CHANGE_PASSWORD_BUTTON = 'click_confirm_change_password_button',
}

export enum WritingPageEvent {
  CLICK_AI_WRITING = 'click_ai_writing',
  CLICK_UPLOAD_FILE = 'click_upload_file',
  CLICK_INSERT_URL = 'click_insert_url',
  CLICK_CLOSE_INPUT = 'click_close_input',
  CLICK_SEND_GENERATE = 'click_send_generate',
  CLICK_ABORT_GENERATE = 'click_abort_generate',
  CLICK_APPLY = 'click_apply',
  CLICK_REGENERATE = 'click_regenerate',
  CLICK_COPY = 'click_copy',
  CLICK_DELETE = 'CLICK_DELETE',
  CLICK_FEEDBACK = 'CLICK_FEEDBACK',
  CLICK_FEEDBACK_SUBMIT = 'click_feedback_submit',
  CLICK_EXPAND = 'click_expand',
  CLICK_CONTINUE_WRITING = 'click_continue_writing',
  CLICK_REWRITE = 'click_rewrite',
  CLICK_TRANSLATE = 'click_translate',
  CLICK_AI_WRITING_PROMPT_HISTORY = 'click_ai_writing_prompt_history',
  CLICK_TOOLBAR_PROMPT_HISTORY = 'click_toolbar_prompt_history',
  UPLOAD_FILE = 'upload_file',
  ADD_LINK = 'add_link',
}

export enum AiBotEvent {
  CLICK_CLEAR_CONTEXT = 'click_clear_context',
  CLICK_MESSAGE_SEND = 'click_message_send',
  CLICK_REGENERATE = 'click_regenerate',
  CLICK_MESSAGE_COPY = 'click_message_copy',
  CLICK_STOP_GENERATOR = 'click_stop_generator',
  CLICK_REFRESH = 'click_refresh',
  CLICK_REPEAT = 'click_repeat',
  CLICK_TOPICS = 'click_topics',
  CLICK_SHOW_AI_BOT = 'click_show_ai_bot',
  CLICK_HIDE_AI_BOT = 'click_hide_ai_bot',
}

export enum ProofreadingEvent {
  PROOFREADING_DRAWER_OPEN = 'proofreading_drawer_open',
  PROOFREADING_DRAWER_CLOSE = 'proofreading_drawer_close',
  CLICK_OPEN_ROOFREADING = 'click_open_roofreading',
  CLICK_ROOFREADING = 'click_roofreading',
  CLICK_DISMISS = 'click_dismiss',
  CLICK_ACCEPT = 'click_accept',
  CLICK_CHECK_ISSUE_TAB = 'click_check_issue_tab',
}
